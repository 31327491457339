<template>
  <div class="body add-card">
    <div class="form">
      <el-form
        :model="addForm"
        :rules="rules"
        ref="addForm"
        label-width="82px"
        label-position="left"
        class="demo-form dialog-form"
      >
        <el-form-item label="IC卡号" prop="cardNo">
          <el-input type="text" v-model="addForm.cardNo"></el-input>
        </el-form-item>
        <el-form-item label="物理卡号" prop="physicalNo">
          <el-input type="text" v-model="addForm.physicalNo"></el-input>
        </el-form-item>
        <el-form-item label="厂家" prop="makerId">
          <el-select clearable v-model.trim="addForm.makerId">
            <el-option
              v-for="item in makerIdList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属公司" prop="companyId">
          <companySelect
            ref="companySelect"
            :clearable="true"
            :companyTree="companyList"
            :valueName="valueNameAdd"
            :value="valueAdd"
            @getValue="getAddGroupId"
          ></companySelect>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  insertCard,
  updateCard,
  getBusinessCompanyTree,
  queryDictsByCodes
} from '@/api/lib/api'
import companySelect from '@/components/treeSelect/companySelect.vue'

export default {
  components: {
    companySelect
  },

  props: {
    item: {
      type: Object,
      default: () => { }
    }
  },

  data () {
    return {
      isEdit: false,
      valueNameAdd: null,
      valueAdd: null,
      companyList: [],
      addForm: {
        companyId: null,
        cardNo: '',
        physicalNo: '',
        makerId: '',
        cardStatus: '0'
      },
      rules: {
        companyId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        cardNo: [{ required: true, message: '不能为空', trigger: 'blur' }],
        makerId: [{ required: true, message: '不能为空', trigger: 'change' }]
      },
      makerIdList: [],
      cardStatusList: []
    }
  },

  methods: {
    // 新增/修改
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          const data = {
            companyId: this.addForm.companyId,
            cardNo: this.addForm.cardNo,
            physicalNo: this.addForm.physicalNo,
            makerId: this.addForm.makerId,
            cardStatus: this.addForm.cardStatus
          }
          if (!this.isEdit) {
            insertCard(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          } else {
            data.id = this.addForm.id
            updateCard(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    },
    getDicts () {
      queryDictsByCodes({ parentCodes: 'SBCJ,ZKZT' }).then((res) => {
        if (res.code === 1000) {
          this.makerIdList = res.data.SBCJ
          this.cardStatusList = res.data.ZKZT
        }
      })
    },
    getAddGroupId (v) {
      this.addForm.companyId = v
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data
        }
      })
    }
  },

  created () {
    this.getDicts()
    this.getCompanyList()
  },
  mounted () {
    if (
      JSON.stringify(this.item) !== '{}' &&
      JSON.stringify(this.item) !== 'null'
    ) {
      this.valueAdd = this.item.companyId
      this.valueNameAdd = this.item.companyName
      this.addForm = { ...this.item }
      this.isEdit = true
    } else {
      this.isEdit = false
    }
  }
}
</script>

<style lang="scss" scoped>
.add-card {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
  }
}
</style>
